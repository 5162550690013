<template>    
        <v-form @submit.prevent="'true'" ref="loginform">
            <v-row dense>
                <v-col>
                    <TextField label="E-mail" :dense="!true"
                        prepend-inner-icon="mdi-account"
                        :rules="[v => !!v || 'E-mail is verplicht']" 
                        v-model="email" required>
                    </TextField>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col>
                    <PasswordField inner-icon label="Wachtwoord" :dense="!true"
                        prepend-inner-icon="mdi-lock"
                        :rules="[v => !!v || 'Wachtwoord is verplicht']" 
                        v-model="password"></PasswordField>
                </v-col>
            </v-row>


            <v-row dense>
                <v-col align="right" justify="right">
                    <slot name="forgotpassword">
                        <router-link to="/forgot-password" class="text-sm base-link">                            
                        <div class="my-1">                            
                            Wachtwoord vergeten?
                        </div>
                        </router-link>
                    </slot>
                </v-col>
            </v-row>
            <v-row dense>
                <Button type="submit" :loading="user.isLoading" block main primary large @click="onLogin">Log in</Button>
            </v-row>

        </v-form>

</template>

<script>
import user from '@app/user'

import TextField from '@controls/input/TextField'
import PasswordField from '@controls/input/PasswordField'
import Button from '@controls/buttons/ActionButton'
import eventbus from "@app/eventbus"

//
// Usage: 
//
//   import LoginForm from '@shared/ui/auth/LoginForm'
//
export default {
    name: 'LoginForm',
    components: {
        TextField, 
        PasswordField,
        Button
    },
    data() {
        return {
            email: "",
            password: "",
        }
    },
    props: {
        col: {
            type: [String],
            default: ""
        }
    }, 
    methods: {
        onLogin: async function() {

            if (!this.$refs.loginform.validate()) {
                return false;
            }
            var result = await this.user.login(this.email, this.password);
            console.log('Yep. result: ', result)
            if (!result.success) {
                eventbus.snackbar.error({text: result.message || "Het inloggen is mislukt"});
                return;
            }
            if (result.action) {
                this.$router.push(`/${result.action}`);
                return;
            }
            // If there is no other action, we are finished. 
            // Next step might be Loading data required by frontend. 
            var result = await this.user.loadAppData();
            if (!result.success) {
                eventbus.snackbar.error({text: result.message || "Het laden van de applicatie is mislukt"});
                return;
            }
            
            // And after loading the data, go to the 'home' page.
            this.$router.push("/");

        },

    },
    setup() {
        user.logout();
        return {
            user: user
        }
    }
}
</script>
