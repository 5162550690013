<template>    

    <v-text-field v-if="noAutoComplete" 
        :append-icon="showClearText ? 'icon-eye' : 'icon-eye-disable'"
        :type="showClearText ? 'text' : 'password'"
        @click:append="showClearText = !disabled && !showClearText"
        class="password"
        :value="dvalue"
        @input="dvalue = $event"
        @keyup.enter="onEnter"
        hide-details 
        :disabled="disabled"
        :dense="dense" 
        outlined
        :rules="rules"
        :placeholder="cPlaceholder"        
        autocomplete="new-password"
        :label="label"
        :prepend-inner-icon="prependInnerIcon"
        >
    </v-text-field>

    <v-text-field v-else
        :append-icon="showClearText ? 'icon-eye' : 'icon-eye-disable'"
        :type="showClearText ? 'text' : 'password'"
        @click:append="showClearText = !disabled && !showClearText"
        class="password"
        :value="dvalue"
        @input="dvalue = $event"
        @keyup.enter="onEnter"
        hide-details 
        :disabled="disabled"
        :dense="dense" 
        outlined
        :rules="rules"
        :placeholder="cPlaceholder"        
        :label="label"
        :prepend-inner-icon="prependInnerIcon"
        >


    </v-text-field>

</template>

<script>
//  import PasswordField from '@controls/input/PasswordField'
//
// Usage: 
//   <PasswordField v-model="password"></PasswordField>
//


export default {
        name: 'PasswordField',
        components: {            
        },
        props: {
            value: {
                type: [String, Number]
            },
            disabled: {
                type: [Boolean]
            },
            noAutoComplete: {
                type: [Boolean],
                default: true,
            },
            dense: {
                type: [Boolean],
                default: true,
            },
            innerIcon: {
                type: [Boolean],
                default: false,
            },
            placeholder: {
                type: [String]
            },
            rules: {
                type: [Array]
            },
            label: {
                type: [String]
            },
            prependInnerIcon: {
                type: [String]
            },

        },
        data () {
            return {                            
                showClearText: false
            }
        },
        computed: {
            cPlaceholder: function() {
                if (this.label) {
                    return null;
                }
                return this.placeholder;
            },
            dvalue: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }	
            },
        },
        methods: {
            clear: function() {
                this.dvalue = null;
            },
            onEnter: function() {
                this.$emit('enter');              
            },

        },
    }
</script>




